@font-face {
  font-family: "Cerebri Sans Pro Regular";
  src: local("Cerebri Sans Pro Regular"), url(./CerebriSansPro-Regular.otf);
}

@font-face {
  font-family: "Cerebri Sans Pro Semi Bold";
  src: local("Cerebri Sans Pro Semi Bold"), url(./CerebriSansPro-SemiBold.otf);
}

@font-face {
  font-family: "Cerebri Sans Pro Italic";
  src: local("Cerebri Sans Pro Italic"), url(./CerebriSansPro-Italic.otf);
}
